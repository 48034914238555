
const API_Gsheet = 'https://script.google.com';

export const POST_GSHEET_API = API_Gsheet + "/macros/s/AKfycbzpzZY5Bz0tKspnEdDmfVrOmCmhoSy4SMR4AM5QlpFRecdn59iZ_oeF4-PLbkY28Kxn/exec"









