import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import './drawer.css';

export default function DrawerMobileNavigation() {
  const [open, setOpen] = React.useState(false);
  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
    setOpen(false); // Close drawer after scrolling
  };
  const handleLinkClick = () => {
    setOpen(false); // Close drawer on link click
  };
  return (
    <React.Fragment>
      <IconButton variant="outlined" color="neutral" onClick={() => setOpen(true)}>
        <Menu />
      </IconButton>
<div className='drawerMainWrapper'>
<Drawer open={open} onClose={() => setOpen(false)} className='drawerSubWrapper'>
      <div className='closeButtonNav'>
     <CloseIcon id="close-icon" onClick={() => setOpen(false)} />
     </div>
        <List
          size="lg"
          component="nav"
          sx={{flex: 'none'}}
        >
          <ListItemButton component={Link} to='/' className="mobNavLink" onClick={handleLinkClick}> Home</ListItemButton>
          <ListItemButton component={Link} to='/product' className="mobNavLink" onClick={handleLinkClick}>Product</ListItemButton>
          <ListItemButton component={Link} to='/pricing' className="mobNavLink" onClick={handleLinkClick}>Pricing</ListItemButton>
          <ListItemButton component={Link} to='/faq' className="mobNavLink" onClick={handleLinkClick}>FAQs</ListItemButton>
          <ListItemButton component={Link} href="#!" onClick={scrollToContact} className="mobNavLink" >Contact Us</ListItemButton>
          <ListItemButton component={Link} to='https://app.publishstory.co/dashboard' className="mobNavLink" onClick={handleLinkClick}>Login</ListItemButton>
        </List>
      </Drawer>
</div>
    </React.Fragment>
  );
}
