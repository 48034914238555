import React, { useContext, useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './pricing.css';
import { DataContext } from '../../App';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bottomleftmask from '../../assets/pngicon/bottomleftmask.png';
import toprightmask from '../../assets/pngicon/toprightmask.png';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import { Button } from '@mui/material';
import Modal from '../Modal/modal';



const Pricing = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const openModal = () => {
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
      };

    
    const data = useContext(DataContext);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Carousel settings
    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 2000,
        className: 'pricingCarousal',
        prevArrow: false,
        nextArrow: false,
        swipeToSlide: true,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true,
              arrows: false,
              dots: true,
              infinite: true,
              initialSlide: 2,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true,
              arrows: false,
              dots: true,
              infinite: true,
            }
          }
        ]
      };
      

    return (
        <div id="pricing" className="plans-box">
            <div className="corner-box top-right">
                <img className="" src={toprightmask} alt="Image 1" />
            </div>
            <div className="corner-box bottom-left">
                <img className="" src={bottomleftmask} alt="Image 1" />

            </div>
            <h2>Choose a plan based on your need</h2>
            <h4>Build beautiful, functional Content Sites with ease. Choose the plan that best fits your needs and budget.</h4>
            <div className="cards-container">
                <div className='pricing-containerhomepage'>
                    <div className="pricing-box">
                        <div className='pricingarea'>
                            <Slider {...settings} >

                                {data?.pricing?.length && data?.pricing.map((pricingElement, index) => (

                                    <div className='mainCardWrap' key={`price-${index}`}>
                                        <div className="plan-card">
                                            <div className='planDetails'>
                                                <h3>{pricingElement?.PlanName}</h3>
                                                <h4><span className="price">{pricingElement?.PlanPrice}</span> <span className="period">{pricingElement?.Period}</span></h4>
                                                {pricingElement.PopularPlan && <div className='popularTag'>
                                                    <p>Popular</p>
                                                </div>}
                                            </div>
                                            <div className='planListing'>
                                                <ul className='planListingPointers'>
                                                    {pricingElement?.PlanFeature.split("##").filter(feature => feature.trim() !== "").map((feature, featureIndex) => (
                                                        featureIndex < 10 && (
                                                        <li key={featureIndex}><span className='checkIconMat'><CheckIcon /></span> {feature}</li>
                                                        )
                                                    ))}
                                                </ul>

                                            </div>

                                            <div className='getPlanButtonWrap'>
                                                <Button className='getPlanButton' onClick={openModal}>GET THE PLAN NOW <p className="iconAngleButton"> <KeyboardArrowRightSharpIcon /> </p></Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />
           
        </div>
    );
};

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} custom-arrow`}
            style={{ ...style }}
            onClick={onClick}
        >

        </div>
    );
};

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} custom-arrow`}
            style={{ ...style }}
            onClick={onClick}
        >

        </div>
    );
};

export default Pricing;
