// Footer.js
import React, { useContext } from 'react';
import './footer.css'; // Import your CSS file for styling
import { DataContext } from '../../App';
import SocialMedia from "../SocialMedia/social";
import PublishStoryIcon from '../../assets/svgicons/publishstory';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
const Footer = () => {
    const data = useContext(DataContext);
    return (
        <footer id="contact" className="footer">
            <div className='corporateContainer'>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <div className="menu-logo manageFooterWidth">
                                {/* <img src={logo} alt="Logo" /> */}
                                {/* <h4>Logo</h4> */}
                                <div className='footerLogoImage'>
                                    <a href='#'>
                                        <PublishStoryIcon />
                                    </a>
                                </div>

                                {/* <hr className='linkunderline' /> */}

                                {data && data?.footer && <div className='corpoFooterAbout'>
                                    <p>{data?.footer[0].About}</p>
                                </div>}
                                <div className='corporateFooterAdress'>
                                    <p className='corpoFooterAdressWrap'> <PlaceOutlinedIcon />  {data?.footer[0].FooterAddress}</p>
                                    <a href={`mailto:${data?.footer[0].mailto}`} className='corpoFooterMailWrap'><MailOutlinedIcon />{data?.footer[0].mailto}</a>
                                </div>

                            </div>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <div className="menu-links manageFooterWidth">
                                <div className="menu1">
                                    <h4>Menu 1</h4>
                                    <hr className='linkunderline' />
                                    <ul>
                                        <li>
                                            <Link to='/'>Home</Link>
                                        </li>
                                        <li>
                                            <Link to='/product'>Product</Link>
                                        </li>
                                        <li>
                                            <Link to='/pricing'>Pricing</Link>
                                        </li>
                                        <li>
                                            <Link to='/faq'>FAQs</Link>
                                        </li>
                                        {/* <li>
                                        <Link to='/contact'>Contact Us</Link>
                                    </li> */}

                                    </ul>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div className="newsletter manageFooterWidth">
                                <h4>Social</h4>
                                <hr className='linkunderline' />

                                <div className="copyright">
                                    <SocialMedia socialData={data && data?.SocialLinks} />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div className="newsletter manageFooterWidth">
                                {data && data?.footer[0].PrivacyPolicy && <div>
                                    <h4>
                                        <Link to='privacy'>Privacy Policy</Link>
                                    </h4>
                                    <hr className='linkunderline' />
                                    <p>{data?.footer[0].PrivacyPolicy}</p>
                                </div>
                                }
                                {data && data?.footer[0].TermsofUse && <div>
                                    <h4 className='corpTreamsHeading'>
                                        <Link to='termsandcondition'>Term Of Use</Link>
                                    </h4>
                                    <hr className='linkunderline' />
                                    <p>{data?.footer[0].TermsofUse}</p>
                                </div>}
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            
            <div>
                <hr />
            </div>
            <div className='footerText'>
                Copyright © 2024 Times Internet Limited. All Rights Reserved.
            </div>
        </footer>



    );
};

export default Footer;
