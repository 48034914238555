import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DataContext } from '../../App';
import './home.css';
import Testimonial from "../Testimonial/testimonial";
import Pricing from "../Pricing/pricing";
import circlePattern from '../../assets/pngicon/Circle_pattern.svg'; // Import your SVG component
import formDots from '../../assets/pngicon/form_dots.svg'; // Import your SVG component
import { Button } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from '../Modal/modal';
import Frame from '../../assets/pngicon/Frame.png';
import featureImage from '../../assets/pngicon/Featured_image.png';
import BookDemoForm from '../BookDemoForm/BookDemoForm'


const Home = () => {
    const data = useContext(DataContext);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="wave">
                <div className='corporateContainer'>
                    <div className='waveContent'>
                        <div className='content'>
                            <div className='homecontent'>
                                <h1>One stop platform for all digital publishing needs</h1>
                                <h4>Create, Design, Engage</h4>
                                <p>Effortlessly build stunning web pages, track your audience's engagement, and focus on creating compelling content.</p>
                                <div className='demoButtonWrapper'>
                                    <Button variant="contained" className="demoButton" onClick={openModal}>
                                        Book Demo
                                    </Button>
                                    <div className='circlePatternImage'>
                                        <img src={circlePattern} alt="circlepng" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='featureImage'>

                            <img className="image2" src={featureImage} alt="Feature Image" />
                            <div className='circleMobilePatternImage'>
                                <img src={circlePattern} alt="circlepng" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='keyFeatureWrapper'>
                <div className="corporateContainer">
                    <div className='additional-content'>
                        <div className='feature-list mobileKeyFeature'>
                            <h2>Key Features</h2>
                        </div>
                        <div className='image-container'>
                            <img className="frameimage" src={Frame} alt="Image 1" />
                        </div>
                        <div className='feature-list desktopKeyFeature'>
                            <h2>Key Features</h2>
                            <ul>
                                {
                                    data?.keyFeatures?.length && data?.keyFeatures?.map((x, i) => {
                                        return (
                                            <li key={`key-${i}`}><p><span className='boldPoints'>{x?.featureHeading}</span> {x?.featureDescription}</p> </li>

                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="demo-box">
                <div className='formDotsImage'>
                    <img src={formDots} alt="dots" />
                </div>
                <div className='demoBoxarea corporateContainer '>
                    <BookDemoForm />
                </div>
            </div>
            <Pricing />
            <Testimonial Userdata={data && data?.testimonial} />
            <Modal isOpen={isModalOpen} onClose={closeModal} />
        </>
    );
};


export default Home;
