import React, { useState, useEffect, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/home';
import Product from './components/Product/product';
import Pricing from './components/Pricing/pricing';
import Blog from './components/Blog/blog';
import Faq from './components/Faq/faq';
import Contact from './components/Contact/contact';
import Header from './components/Header/header';
import axios from 'axios';
import Footer from './components/Footer/footer';
import Privacy from './components/Privacy/privacy';
import Terms from './components/Terms/terms';
import Status from './components/Status/status';
import Testimonial from './components/Testimonial/testimonial';
import SocialMedia from './components/SocialMedia/social';
import Drawer from './components/Drawer/drawer';
import BookDemoForm from './components/BookDemoForm/BookDemoForm';

export const DataContext = createContext();

const App = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let url;
      if (window.location.hostname === 'cworigin.publishstory.co' || window.location.hostname === 'publishstory.co') {
        url = 'https://publishstory.co/corporatewebsite/cwdata.json';
      } else {
        url = 'https://script.google.com/macros/s/AKfycbxYVcWYWPtUpdpyU2QCFDp8LN_Kzoyvq5sQe5elLYVMVt0ifxEijna2k-O5KI8AKaF2/exec';
      }
      const response = await axios.get(url);
      setData(response.data);
    };

    fetchData();
  }, []);

  return (
    <DataContext.Provider value={data}>
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/faq" element={<Faq />} />
          {/* <Route path="/contact" element={<Contact />} /> */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/termsandcondition" element={<Terms />} />
          <Route path="/status" element={<Status />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/social" element={<SocialMedia />} />
          <Route path="/drawer" element={<Drawer />} />
          <Route path="/form" element={<BookDemoForm />} />

          

        </Routes>
      </main>
      <Footer />
    </Router>
  </DataContext.Provider>
  );
};

export default App;
