import React, { useState } from 'react';
import './BookDemoForm.css'; 
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import {POST_GSHEET_API} from '../../Services/apiServices'
const BookDemoForm = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        companyName: '',
        phone: '',
        optInMarketing: false,
        termsAndConditions: false
    });
    const [sucessMsg, setSuccessMsg] = useState(false)
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        companyName: '',
        phone: ''
    });
    const phoneRegex = /^[0-9 ()+-]*$/;
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === 'phone') {
            if (value !== '' && !phoneRegex.test(value)) {
                setFormErrors({ ...formErrors, [name]: 'Invalid phone number' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        }
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };


    

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
         // Check if there are any validation errors
         const hasErrors = Object.values(formErrors).some(error => error !== '');
         if (hasErrors || formErrors.phone !== '') {
             setLoading(false);
             return; // Don't submit if there are errors, including phone number validation error
         }

        try {
            const timestamp = new Date().toISOString();
            const payload = {
                timestamp: timestamp,
                ...formData
            };
            const response = await axios.post(POST_GSHEET_API, new URLSearchParams(payload).toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (response.data.result === 'success') {
                setSuccessMsg(true)

            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className='inlineform formBoxContainer'>
            {sucessMsg ? <div className='thanksMsg'>Thanks for your Query</div> : <div>
                <h2>Book Demo</h2>
                <h4>Find Out More About Publish Story</h4>
                <div className="form-container">
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <input type="text" id="name" name="name" placeholder='Name' className="form-control" value={formData.name} onChange={handleChange} required />
                            <input type="email" id="email" name="email" placeholder='Email ID' className="form-control" value={formData.email} onChange={handleChange} required />
                            <input type="text" id="company" name="companyName" placeholder='Company Name' className="form-control" value={formData.companyName} onChange={handleChange} required />
                            <div className='PhoneWrapperCorp'><input type="tel" id="phone" name="phone" placeholder='Phone' className="form-control" value={formData.phone} onChange={handleChange} required />
                            {formErrors.phone && <span className="error">{formErrors.phone}</span>}
                            </div>
                            <div className='desktopSubmitButton'>
                            <button type="submit">Submit</button>
                            </div>
                        </div>

                        <div className='formCheckboxWrapper'>
                            <label className='marketingBox'>
                                <input type="checkbox" name="optInMarketing" checked={formData.optInMarketing} onChange={handleChange} />
                                Opt in for Marketing
                            </label>
                            <label>
                                <input required type="checkbox" name="termsAndConditions" checked={formData.termsAndConditions} onChange={handleChange} />
                                I accept to   <Link to='termsandcondition'  target='_blank'>Terms & Conditions</Link> and  <Link to='privacy'  target='_blank'>Privacy Policy</Link>
                            </label>
                        </div>
                        <div className='mobileSubmitButton'>
                            <button type="submit">Submit</button>
                            </div>
                    </form>
                    {loading && <div className="loader-overlay"><Circles color="#00BFFF" height={50} width={50} /></div>}
                </div>
            </div>}

        </div>

    );
};

export default BookDemoForm;
