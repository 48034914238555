import React, { useState, useEffect } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import './Modal.css'; // Import your CSS file for styling
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
import {POST_GSHEET_API} from '../../Services/apiServices';
//import ReCAPTCHA from 'react-google-recaptcha';

const Modal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        companyName: '',
        phone: '',
        optInMarketing: false,
        termsAndConditions: false
    });
    const [sucessMsg, setSuccessMsg] = useState(false)
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        companyName: '',
        phone: ''
    });
    const [captchaToken, setCaptchaToken] = useState('');
    const phoneRegex = /^[0-9 ()+-]*$/;
    useEffect(() => {
        if (isOpen) {
            setSuccessMsg(false); // Reset success message state when modal is opened
        }
    }, [isOpen]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === 'phone') {
            if (value !== '' && !phoneRegex.test(value)) {
                setFormErrors({ ...formErrors, [name]: 'Invalid phone number' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        }
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };
    // const handleCaptchaChange = (token) => {
    //     setCaptchaToken(token);
    // };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        // Check if there are any validation errors
        const hasErrors = Object.values(formErrors).some(error => error !== '');
        //|| !captchaToken
        if (hasErrors || formErrors.phone !== '') {
            setLoading(false);
            return; // Don't submit if there are errors, including phone number validation error
        }

        try {
            const timestamp = new Date().toISOString();
            const payload = {
                timestamp: timestamp,
                ...formData
            };
            const response = await axios.post(POST_GSHEET_API, new URLSearchParams(payload).toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (response.data.result === 'success') {
                setSuccessMsg(true)
                //onClose();
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (

        <div id="myModal" className="modal">
            <div className="modal-content">
                <div onClick={() => onClose()} className='modleCloseButton close'> <CloseRoundedIcon /> </div>
                {sucessMsg ? <div className='thanksMsg'>Thanks for your Query</div> : <div>
                    <p className="corporateFormMessege">We will Contact</p>
                    <p className="corporateFormHeading">Book A Demo</p>
                    <p className="corporateFormSubHeading">Find out more about PublishStory</p>
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <input type="text" id="name" name="name" placeholder='Name' className="form-control" value={formData.name} onChange={handleChange} required />

                            <input type="email" id="email" name="email" placeholder='Email ID' className="form-control" value={formData.email} onChange={handleChange} required />

                            <input type="text" id="company" name="companyName" placeholder='Company Name' className="form-control" value={formData.companyName} onChange={handleChange} required />
                            <div className='PhoneWrapperCorpModal'>
                            <input type="tel" id="phone" name="phone" placeholder='Phone' className="form-control" value={formData.phone} onChange={handleChange} required />
                            {formErrors.phone && <span className="error">{formErrors.phone}</span>}
                            </div>
                            <div className="checkbox-group">
                                <label className='marketingBox'>
                                    <input type="checkbox" name="optInMarketing" checked={formData.optInMarketing} onChange={handleChange} />
                                    Opt in for Marketing
                                </label>
                                <label>
                                    <input required type="checkbox" name="termsAndConditions" checked={formData.termsAndConditions} onChange={handleChange} />
                                    I accept to   <a href='termsandcondition' target='_blank'>Terms & Conditions</a> and  <a href='privacy'  target='_blank'>Privacy Policy</a>
                                </label>
                            </div>
                            {/* <ReCAPTCHA
                                    sitekey="6LdTpAMqAAAAAKOcKXdqJPojQ2U5W7bSneJTkrPO"
                                    onChange={handleCaptchaChange}
                                /> */}
                            <button type="submit" className='corporateSubmitButton'>Submit</button>
                        </form>
                        {loading && <div className="loader-overlay"><Circles color="#00BFFF" height={50} width={50} /></div>}
                    </div>
                </div>}
            </div>
        </div>


    );
};

export default Modal;
