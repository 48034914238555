import React from 'react';
import './social.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faLinkedinIn, faPinterestP, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const platformIcons = {
  facebook: faFacebookF,
  twitter: faTwitter,
  linkedin: faLinkedinIn,
  pinterest: faPinterestP,
  whatsapp: faWhatsapp
};

const SocialMedia = (props) => {
  return (

    <div className='socialMediaWrapper'>
      <ul>
        {props?.socialData && props?.socialData.length > 0 && props?.socialData.map((social, index) => (
          <li key={index}>
            <a href={social.Link} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={platformIcons[social.Platform.toLowerCase()]} />
            </a>
          </li>
        ))}
      </ul>
    </div>

  );
};

export default SocialMedia;
