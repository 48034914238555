import React,{useContext} from 'react';
import './status.css';
//import { DataContext } from '../../App';


const Status = () => {
  //const data = useContext(DataContext);

  return (
    <div style={{textAlign:'center'}}>DB Status OK</div>
  );
};

export default Status;
