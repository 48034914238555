import React, { useContext, useState } from 'react';
import { SvgIcon } from '@mui/material';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import './header.css';
import { DataContext } from '../../App';
import PublishStoryIcon from '../../assets/svgicons/publishstory';
import Drawer from '../Drawer/drawer';
// import PublishStory from './assets/svgicons/publishstory.svg';
import Modal from '../Modal/modal'; // Import your Modal component
const Header = () => {
  const data = useContext(DataContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToPricing = () => {
    const pricingSection = document.getElementById('pricing');
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <AppBar position="sticky" className="appBar">

<Toolbar className='corporateToolBar'>
        {/* <Typography variant="h6" className="title"> */}
        <div className='corporateContainer'>

        <div className='mainHeaderWrapperAll'>
        <div className='mobileNavigationDrawer'>
<Drawer/>
</div>

        <Box className="leftContent">

        <a href="/" component={Link} to='/'><PublishStoryIcon name="mail1"/></a>  


        </Box>

        {/* </Typography> */}
        <Box className="navLinks desktopNavigationCorporate">
          {
            <>
            <Button  component={Link} to='/' className="navLink">
              Home
            </Button>
              <Button  component={Link} to='/product' className="navLink">
                Product
              </Button>
              <Button  component={Link} to='/pricing' className="navLink">
                Pricing
              </Button>
              <Button  component={Link} to='/faq' className="navLink">
                FAQs
              </Button>
              <Button  href="#!" onClick={scrollToContact} className="navLink"> 
                Contact Us
              </Button>
              <Button  component={Link} to='https://app.publishstory.co/dashboard' className="navLink" target='_blank'> 
                Log in
              </Button>
            </>


            // data?.headerName?.length && data?.headerName.map((x, i) => {
            //   if (x?.HeaderName !== "Contact Us" ) {
            //     return (
            //       <Button key={i} component={Link} to={x?.Url} className="navLink">
            //         {x?.HeaderName}
            //       </Button>
            //     );
            //   } else {
            //     return (
            //       <Button key={i} onClick={x?.HeaderName === "Contact Us" ? scrollToContact : scrollToPricing} className="navLink">
            //         {x?.HeaderName}
            //       </Button>
            //     );
            //   }
            // })
          }

        </Box>
        <Box className="rightContent">
          <Button variant="contained" className="demoButton" onClick={openModal}>
            Book Demo
          </Button>
        </Box>
        </div>


        </div>


      </Toolbar>
      <Modal isOpen={isModalOpen} onClose={closeModal} />

    </AppBar>
  );
};

export default Header;
