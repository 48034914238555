import React, { useState,useContext,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './faq.css';
import fullWidthImage from '../../assets/pngicon/faqmask.png'; // Replace with your image path
import { DataContext } from '../../App';




const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    const data = useContext(DataContext);
    const handleAccordionClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

  const headingData = data?.faq?.length && data?.faq.filter(x=> !!x?.AllHeading)

  return (
<div className='faqWrapper'>

<div className="product-image">
        <img src={fullWidthImage} alt="Product" />
        <div className='headingOnImage'>
        <h1 className="key-features-heading">FAQs</h1>
        </div>
      </div>
{/* <div className="faq-header">
            <img src={fullWidthImage} alt="FAQ Header" className="full-width-image" />
            <h1 className="faq-title">FAQ</h1>
        </div> */}


<div className="corporateContainer">
        {
    headingData?.length && headingData.map((x, index) => {
        return (
            !!x?.Heading && 
            <div className="faq-content" key={index}>
                <h2 className="faq-group-heading">{x.AllHeading}</h2>
                <div className="faq-group">
                    {data?.faq?.length && data?.faq
                        .filter(item => item.Heading === x.AllHeading) // Assuming that `item.Group` matches `x.Heading`
                        .map((item, index) => (
                            <div className="accordion-item" key={index}>
                                <div className="accordion-header" onClick={() => handleAccordionClick(index)}>
                                    <h3 className="accordion-question">{item.Question}</h3>
                                    <span className="accordion-icon">{activeIndex === index ? '-' : '+'}</span>
                                </div>
                                {activeIndex === index && (
                                    <div className="accordion-body">
                                        <p>{item.Answer}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </div>
        );
    })
}

        {/* <div className="faq-content">
            <h2 className="faq-group-heading">Frequently Asked Questions</h2>
            <div className="faq-group">
                {faqData.map((item, index) => (
                    <div className="accordion-item" key={index}>
                        <div className="accordion-header" onClick={() => handleAccordionClick(index)}>
                            <h3 className="accordion-question">{item.question}</h3>
                            <span className="accordion-icon">{activeIndex === index ? '-' : '+'}</span>
                        </div>
                        {activeIndex === index && (
                            <div className="accordion-body">
                                <p>{item.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
         
          
        </div>
        <div className="faq-content">
            <h2 className="faq-group-heading">Frequently Asked Questions</h2>
            <div className="faq-group">
                {faqData.map((item, index) => (
                    <div className="accordion-item" key={index}>
                        <div className="accordion-header" onClick={() => handleAccordionClick(index)}>
                            <h3 className="accordion-question">{item.question}</h3>
                            <span className="accordion-icon">{activeIndex === index ? '-' : '+'}</span>
                        </div>
                        {activeIndex === index && (
                            <div className="accordion-body">
                                <p>{item.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
         
          
        </div> */}
    </div>
</div>
);
};

export default Faq;