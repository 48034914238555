import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './testimonial.css';
import ArrowRightAltSharpIcon from '@mui/icons-material/ArrowRightAltSharp';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { IconButton } from '@mui/material';

const renderStars = (rating) => {
  const totalStars = 5;
  const stars = [];

  for (let i = 0; i < totalStars; i++) {
    if (i < rating) {
      stars.push(<StarRoundedIcon key={i} />);
    } else {
      stars.push(<StarBorderRoundedIcon key={i} />);
    }
  }

  return stars;
};

const TestimonialCarousal = (props) => {

 
  const CustomPrevArrow = ({ onClick }) => (
    <IconButton onClick={onClick} className='CustomNextArrow'>
      <ArrowRightAltSharpIcon />
    </IconButton>
  );

  const CustomNextArrow = ({ onClick }) => (
    <IconButton onClick={onClick} className='CustomPrevArrow'>
      <ArrowRightAltSharpIcon />
    </IconButton>
  );




  // const cache = getMUICache();
  const settings = {
    dots: false,
    // arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    className: 'TestimonialCarousal',
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    swipeToSlide: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          autoplay: true,
        }
      }
    ],
    rtl: false 
  };
 
  return ( props?.Userdata && props?.Userdata.length > 0 && <div className='commoncontainer'>
      <div className="testimonial-box">
        <div className='testimonialarea?'>
          <div className="testimonial-header">
            <h2>Our Testimonial</h2>
          </div>
          <h4>What Our Awesome Customer Say’s About Us</h4>
          <Slider {...settings} >

            {props?.Userdata.map((testimonial, index) => (
              <div className='testimonialCardWrapper' key={index}>
                <div className="testimonial-card" >
                  <div className="stars">
                  {renderStars(testimonial.Star)}
                  </div>
                  <p>“{testimonial.Description}”</p>
                  <div className="testimonial-user">
                    <div className='userTestimonalImage'>
                      <img src={testimonial.userImage} alt="User" />
                    </div>
                    <div className='testimonialUserDetails'>
                      <p className='testimonialUserName'>{testimonial.Name}</p>
                      <p className='testimonialUserDesiganation'>{testimonial.Designation}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </Slider>
        </div>
      </div>
    </div>

  )

}

export default TestimonialCarousal;