import React, { useState,useContext } from 'react';
import './blog.css';
import topImage from '../../assets/pngicon/blogmask.png'; // Replace with your image path
// import avatar from '../../assets/avatar.png'; // Replace with your avatar image path
import { DataContext } from '../../App';




const BlogPage = () => {
  const data = useContext(DataContext);
const blogData=data?.blog;
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 9;

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = blogData?.length && blogData.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="blog-container">
            <div className="blog-header">
                <img src={topImage} alt="Blog Header" className="full-width-image" />
                <h1 className="blog-title">Blog</h1>
            </div>
            <div className='blogcontainersub'>
            <div className='blogheading'>
              <h1>Blog</h1></div>
            <div className="blog-content">
                <div className="blog-cards">
                    {currentPosts?.length && currentPosts.map((post) => (
                        <div className="blog-card" key={post.id}>
                            <img src={post.image} alt={post.title} className="blog-card-image" />
                            <h2 className="blog-card-title">{post.title}</h2>
                            <p className="blog-card-text">{post.text}</p>
                            <div className="blog-card-footer">
                              <div className='imgclass'>
                              <img src={""} alt={post.author} className="blog-card-avatar" />

                              </div>
                                <div className="blog-card-author">
                                    {post.author}
                                </div>
                                <div className="blog-card-designation">
                                    {post.designation}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="pagination">
                  {
                    blogData?.length &&
                    [...Array(Math.ceil(blogData.length / postsPerPage)).keys()].map((number) => (
                      <button
                          key={number + 1}
                          onClick={() => paginate(number + 1)}
                          className={`pagination-button ${currentPage === number + 1 ? 'active' : ''}`}
                      >
                          {number + 1}
                      </button>
                  ))
                  }
                
                </div>
            </div>
            </div>
          
        </div>
    );
};

export default BlogPage;
